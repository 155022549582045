import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from "react-router-dom";


const NavbarPage = () => (
    <div className={'col-12 p-0'}>
        <Navbar collapseOnSelect expand="md" className="bg-navbar-color justify-content-center py-2" fixed={"top"}>
            <Container className='row d-flex justify-content-between'>
                <Navbar.Brand className="col-md-4 col-5">
                    <div className="row">
                        <div className="col-md-10 col-12 font-color-white d-flex justify-content-center align-items-center ">
                            <Link to={"/"}>
                                <img className="img-fluid bg-transparent" src={"/images/landing/logo.png"} alt="logo"/>
                            </Link>
                        </div>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className={"col-2 border-0"} />

                <Navbar.Collapse id="" className={"col-md-6 col-12 d-md-flex justify-content-end "}>
                    <Nav className="d-md-flex gap-md-5 gap-3 justify-content-end align-items-center">
                        <Nav>
                            <div>
                                <Link to={"/privacy-policy"}>
                                    <button className="px-0 border-0 bg-transparent font-color-primary-default font-size-16 font-weight-400">
                                        Privacy Policy
                                    </button>
                                </Link>
                            </div>
                        </Nav>
                        <Nav>
                            <div>
                                <Link to={"/terms-conditions"}>
                                    <button className="px-0 border-0 bg-transparent font-color-primary-default font-size-16 font-weight-400">
                                        Terms and Conditions
                                    </button>
                                </Link>
                            </div>
                        </Nav>
                        <Nav className={"d-none d-md-block"}>
                            <div className={""}>
                               <Link to={"/contactus"}>
                                   <button className="border-0 btn-primary-color font-size-18 text-white font-weight-500">
                                       Contact us
                                   </button>
                               </Link>
                            </div>
                        </Nav>
                        <Nav className={"w-100 d-flex d-md-none"}>
                            <div className={"w-100"}>
                                <button className="border-0 w-100 btn-primary-color font-size-18 text-white font-weight-500">
                                    Contact us
                                </button>
                            </div>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>

                {/*<Navbar.Collapse className={"col-md-4"}>*/}
                {/*    <Nav className="d-flex align-items-md-center py-3 py-md-0 w-100 justify-content-md-end">*/}
                {/*        <Nav>*/}
                {/*            <div>*/}
                {/*                <Link to={"/book-an-appointment"}>*/}
                {/*                    <button type="button" className="button-bg text-white font-size-14 font-weight-500p font-family-raleway border-none">*/}
                {/*                        Book an appointment*/}
                {/*                    </button>*/}
                {/*                </Link>*/}
                {/*            </div>*/}
                {/*        </Nav>*/}
                {/*    </Nav>*/}
                {/*</Navbar.Collapse>*/}
            </Container>
        </Navbar>
    </div>
)

export default NavbarPage
