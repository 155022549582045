import FooterPage from "../FooterPage/footerPage";
import NavbarPage from "../NavBarPage/navbarPage";
import {useEffect} from "react";

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"container-fluid p-0"}>
            <NavbarPage/>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"row pt-5 bg-background-light-sky-color"}>
                        <div className={"col-12 "}>
                            <div className={"row justify-content-center py-4 my-md-5"}>
                                <div className={"col-10"}>
                                    <div
                                        className="w-100 d-flex flex-column pb-4 mb-md-3 align-items-center justify-content-center">
                                        <label
                                            className={"font-color-primary-100 text-center font-family-play font-size-42 font-weight-700"}>
                                            Terms and Conditions
                                        </label>
                                        <div className={"mt-md-4 mt-2"}>
                                            <label className={"font-size-18 font-weight-400 font-color-primary-100"}>Effective
                                                date: April 3, 2023</label>
                                        </div>
                                    </div>

                                    <div className={""}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-family-play font-weight-700"}>Terms
                                                & Conditions</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                These Terms of Use (“Terms of Use”) have been drafted in accordance with
                                                the provisions of Rule 3 (1) of the Information Technology Rules, 2011
                                                that require publishing the rules and regulations, privacy policy and
                                                Terms of Use for access or usage of https://www.schematix.com/
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-size-24 font-weight-700 font-family-play"}>About</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                The domain name https://www.schematix.com/ (hereinafter referred to as "
                                                Website) owned and managed by Schematix, a one person company by shares
                                                incorporated in India under the Companies Act, 2013.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-size-24 font-family-play font-weight-700"}>Terms
                                                of Use</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR
                                                ACCEPTANCE TO ALL THE TERMS AND CONDITIONS HEREIN. PLEASE READ THESE
                                                TERMS OF USE CAREFULLY BEFORE PROCEEDING. IF YOU DO NOT AGREE WITH THE
                                                SAME, PLEASE DO NOT USE THIS WEBSITE.
                                            </label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                For the purpose of these Terms of Use, wherever the context so requires
                                                "You" or "User" shall mean any natural or legal person visits our
                                                platform, either just for the purpose of browsing the Website or engages
                                                to buy our products & services. This Website allows the User to surf the
                                                Website. The term "We", "Us", "Our" shall mean Company.
                                            </label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                Your use of the Website and services and tools are governed by the
                                                following terms and conditions ("Terms of Use") as applicable to the
                                                Website including the applicable policies which are incorporated herein
                                                by way of reference. If You transact on the Website, You shall be
                                                subject to the policies that are applicable to the Website for such
                                                transaction. By mere use of the Website, You shall be contracting with
                                                Company and these terms and conditions including the policies constitute
                                                your binding obligations, with the Website. Any new features or tools
                                                which are added to the current store shall also be subject to the Terms
                                                of Use. We reserve the right to update, change or replace any part of
                                                these Terms of Use by posting updates and/or changes to Our Website. It
                                                is your responsibility to check this page periodically for changes.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-size-24 font-family-play font-weight-700"}>Eligibility
                                                to Use</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                By agreeing to these Terms of Use, you represent that you are persons
                                                who can form legally binding contracts under Indian Contract Act, 1872.
                                                Persons who are "incompetent to contract" within the meaning of the
                                                Indian Contract Act, 1872 including minors, un-discharged insolvents
                                                etc. are not eligible to use the Website. As a minor, if you wish to use
                                                or transact on a Website, such use or transaction may be made by your
                                                legal guardian or parents on the Website. Company reserves the right to
                                                refuse You access to the Website if it is brought to Company &aposs
                                                notice or if it is discovered that you are under the age of 18 years. By
                                                visiting Company Website or accepting these Terms of Use, You represent
                                                and warrant to Company that You are 18 years of age or older and that
                                                You have the right, authority and capacity to use the Website and agree
                                                to and abide by these Terms of Use. You also represent and warrant to
                                                the Company that You will use the Website in a manner consistent with
                                                any and all applicable laws and regulations.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-size-24 font-family-play font-weight-700"}>Privacy
                                                and Information Protection</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR
                                                ACCEPTANCE TO ALL THE TERMS AND CONDITIONS HEREIN. PLEASE READ THESE
                                                TERMS OF USE CAREFULLY BEFORE PROCEEDING. IF YOU DO NOT AGREE WITH THE
                                                SAME, PLEASE DO NOT USE THIS WEBSITE.
                                            </label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                Please review our Privacy Policy, which also governs your visit to this
                                                Site, to understand our internal policies and practices. The personal
                                                information/data provided to us by you during the course of usage of
                                                this website will be treated as strictly confidential and in accordance
                                                with the Privacy Policy and applicable laws and regulations. If You
                                                object to your information being transferred or used, please do not use
                                                the website.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Rights
                                                of use</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                Company grants you limited rights to access and make personal use of
                                                this website, but not to download (other than page caching) or modify
                                                it, or any portion of it. These rights do not include any commercial use
                                                of this website or its contents; any collection and use of any content,
                                                descriptions, or prices; any derivative use of this website or its
                                                contents; any downloading or copying of account information for the
                                                benefit of a third-party; or any use of data mining, robots, or similar
                                                data gathering and extraction tools. This website or any portion of this
                                                website (including but not limited to any copyrighted material,
                                                trademarks, or other proprietary information) may not be reproduced,
                                                duplicated, copied, sold, resold, visited, distributed or otherwise
                                                exploited for any commercial purpose.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Your
                                                Conduct</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                You must not use the Website in any way that causes, or is likely to
                                                cause, the Website or access to it to be interrupted, damaged or
                                                impaired in any way. You understand that you, and not Company, are
                                                responsible for all electronic communications and content sent from your
                                                computer to us and you must use the Website for lawful purposes only.
                                                You must not use the Website for any of the following:
                                            </label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                For Fraudulent Purposes, Or In Connection With A Criminal Offense Or
                                                Other Unlawful Activity; To Send, Use Or Reuse Any Material That Does
                                                Not Belong To You; Or Is Illegal, Offensive (Including But Not Limited
                                                To Material That Is Sexually Explicit Content Or Which Promotes Racism,
                                                Bigotry, Hatred Or Physical Harm), Deceptive, Misleading, Abusive,
                                                Indecent, Harassing, Blasphemous, Defamatory, Libelous, Obscene,
                                                Pornographic, Pedophilic Or Menacing; Ethnically Objectionable,
                                                Disparaging Or In Breach Of Copyright, Trademark, Confidentiality,
                                                Privacy Or Any Other Proprietary Information Or Right; Or Is Otherwise
                                                Injurious To Third Parties; Or Relates To Or Promotes Money Laundering
                                                Or Gambling; Or Is Harmful To Minors In Any Way; Or Impersonates Another
                                                Person; Or Threatens The Unity, Integrity, Security Or Sovereignty Of
                                                India Or Friendly Relations With Foreign States; Or Objectionable Or
                                                Otherwise Unlawful In Any Manner Whatsoever; Or Which Consists Of Or
                                                Contains Software Viruses, Political Campaigning, Commercial
                                                Solicitation, Chain Letters, Mass Mailings Or Any "Spam”; Or Violates
                                                Any Law For The Time Being In Force; Or Deceives Or Misleads The
                                                Addressee About The Origin Of Such Messages Or Communicates Any
                                                Information Which Is Grossly Offensive Or Menacing In Nature; To Not
                                                Make Any Unauthorized Commercial Use Of This Website Or Service And You
                                                May Not Resell This Service Or Assign Your Rights Or Obligations Under
                                                These Terms Of Usage. You May Not Reverse Engineer Any Part Of This
                                                Website Or Service. For Any Act That Causes, Or May Be Likely To Cause
                                                Damage Or Impairment To The Website Or In Any Manner Harms The Company
                                                Or Any Other Person Or Entity (As Determined By The Company In Its Sole
                                                Discretion) Or Interrupt Free Access To It In Any Way. Medical Records
                                                And History Biometric Information Information Received By Body Corporate
                                                Under Lawful Contract Or Otherwise Visitor Details As Provided At The
                                                Time Of Registration Or Thereafter And Call Data Records.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Our
                                                Policies</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                Disclaimer of Warranties and Liabilities We expressly disclaim any
                                                warranties or representations (express or implied) in respect of
                                                quality, suitability, accuracy, reliability, completeness, timeliness,
                                                performance, safety, merchantability, fitness for a particular purpose,
                                                or legality of the products listed or displayed or the content
                                                (including product information and/or specifications) on the Website.
                                                While we have taken precautions to avoid inaccuracies in content, this
                                                Website, all content, information, software, products, services &
                                                related graphics are provided as is, without warranty of any kind.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Indemnity
                                                and Release</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                You shall indemnify and hold harmless Company, its subsidiaries,
                                                affiliates and their respective officers, directors, agents and
                                                employees, from any claim or demand, or actions including reasonable
                                                attorney &apos s fees, made by any third party or penalty imposed due to
                                                or arising out of your breach of these Conditions of Use or any document
                                                incorporated by reference, or your violation of any law, rules,
                                                regulations or the rights of a third party.
                                            </label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                You hereby expressly release Company and/or its affiliates and/or any of
                                                its officers and representatives from any cost, damage, liability or
                                                other consequence of any of the actions/inactions of the vendors and
                                                specifically waiver any claims or demands that you may have in this
                                                behalf under any statute, contract or otherwise.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Communications</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                When you visit https://www.schematix.com/ or send e-mails to us, you are
                                                communicating with us electronically. We may communicate with you by
                                                e-mail, SMS, phone call or by posting notices on the website or by any
                                                other mode of communication. For contractual purposes, you consent to
                                                receive communications including SMS, e-mail or phone calls from us.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Losses</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                We will not be responsible for any business loss (including loss of
                                                profits, revenue, contracts, anticipated savings, data, goodwill or
                                                wasted expenditure) or any other indirect or consequential loss that is
                                                not reasonably foreseeable to both you and us when you commenced using
                                                the website.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Alteration
                                                of Service or Amendments to the Conditions</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                We reserve the right to make changes to our website, policies, and these
                                                Conditions of Use at any time. You will be subject to the policies and
                                                Conditions of Use in force at the time that you use the website, unless
                                                any change to those policies or these conditions is required to be made
                                                by law or government authority. If any of these conditions is deemed
                                                invalid, void, or for any reason unenforceable, that condition will be
                                                deemed severable and will not affect the validity and enforceability of
                                                any remaining condition.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Events
                                                beyond our reasonable control</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                We will not be held responsible for any delay or failure to comply with
                                                our obligations under these conditions if the delay or failure arises
                                                from any cause which is beyond our reasonable control. This condition
                                                does not affect your statutory rights.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Waiver</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                If you breach these conditions and we take no action, we will still be
                                                entitled to use our rights and remedies in any other situation where you
                                                breach these conditions.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Governing
                                                law and Jurisdiction</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                These conditions are governed by and construed in accordance with the
                                                laws of India. You agree, as we do, to submit to the exclusive
                                                jurisdiction of the courts at Hyderabad.
                                            </label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                IN NO EVENT SHALL SCHEMATIX BE LIABLE FOR ANY SPECIAL, INCIDENTAL,
                                                INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE
                                                TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE
                                                POSSIBILITY OF SUCH DAMAGES.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Severability
                                                & Waiver Contact Information</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                If any provision of this Terms of Use is held to be invalid or
                                                unenforceable, such provision shall be struck and the remaining
                                                provisions shall be enforced.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"mt-md-5 mt-3"}>
                                        <div>
                                            <label
                                                className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Contact
                                                Information</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label
                                                className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                                This site is owned and operated by Schematix. If you have any question,
                                                issue, complaint regarding any of our Services, please contact
                                                us. </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPage/>
        </div>
    )
}

export default TermsAndConditions
