import NavbarPage from "../NavBarPage/navbarPage";
import FooterPage from "../FooterPage/footerPage";
import {Link} from "react-router-dom";
import {useEffect} from "react";

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-body-background-color"}>
                <div className={"col-12"}>
                    <NavbarPage/>
                    <div className={"row"}>
                        <div className={"col-12 py-4"}></div>
                    </div>

                    {/* hero section */}
                    <div className={"row"}>
                        <div className={"col-12 py-4 my-md-4"}>
                            <div className={"row justify-content-center"}>
                                <div className={"col-11"}>
                                    <div
                                        className={"row bg-hero-section-container justify-content-start align-items-center br-16"}>
                                        <div className={"col-12 col-md-1"}></div>
                                        <div className={"col-12 col-md-6 p-4 py-5 my-md-5"}>
                                            <div className={"w-100 d-flex text-md-start text-center"}>
                                                <label
                                                    className={"font-size-48 font-family-play text-white font-weight-700"}>
                                                    Welcome to Schematix
                                                </label>
                                            </div>
                                            <div className={"mt-4 w-100 d-flex text-md-start text-center"}>
                                                <label
                                                    className={"font-color-neutral-grey-lightest font-size-18 font-weight-500"}>
                                                    At Schematix, we're dedicated to providing innovative technology
                                                    services tailored to meet the unique needs of your business. Whether
                                                    you're a startup looking to establish a digital presence or an
                                                    established enterprise seeking to optimize your operations, we have
                                                    the expertise and solutions to propel you forward in today's digital
                                                    landscape.
                                                </label>
                                            </div>
                                            <div
                                                className={"mt-4 pt-3 w-100 d-flex justify-content-md-start justify-content-center"}>
                                                <Link to={"/contactus"}>
                                                    <button type={"button"}
                                                            className={"font-size-18 btn-contact-us border-0 font-weight-500 text-white"}>Contact
                                                        us
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* our services */}
                    <div className={"row justify-content-center py-md-2"}>
                        <div className={"col-12 col-md-10 py-4 my-md-4"}>
                            <div className={"mb-md-5 pb-md-2 pb-4 text-center text-md-start"}>
                                <label className={"font-size-42 font-weight-700 font-color-neutral-grey-darkest"}>Our
                                    Services</label>
                            </div>
                            <div className={"row justify-content-md-between our-services-details-container"}>
                                <div
                                    className={"col-12 col-md-6 d-none d-md-block our-services-container-image our-services-details-left-container"}>
                                    <div>
                                        <img className={"img-fluid"} src={"/images/landing/our-services-image.png"}
                                             alt={"our services image"}/>
                                    </div>
                                </div>
                                <div className={"col-12 col-md-6 px-4"}>
                                    <div className={"row px-2"}>
                                        <div className={"col-12 justify-content-center"}>
                                            <div
                                                className={"row p-md-4 px-3 py-4 text-center text-md-start bg-white our-services-container-border mb-md-4 mb-3"}>
                                                <div className={"col-md-2 col-12 px-2"}>
                                                    <div>
                                                        <img className={"img-fluid"}
                                                             src={"/images/landing/our-services-web-development-image.png"}
                                                             alt={"our services web development image"}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 py-3 px-2"}>
                                                    <label
                                                        className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>
                                                        Web Development
                                                    </label>
                                                </div>
                                                <div className={"col-12 px-2"}>
                                                    <label
                                                        className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                        Harness the power of the web with our custom web development
                                                        services. From sleek and responsive websites to complex web
                                                        applications, our team of experienced developers will bring your
                                                        vision to life.
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className={"row p-md-4 px-3 py-4 text-center text-md-start bg-white our-services-container-border mb-md-4 mb-3"}>
                                                <div className={"col-12 col-md-2 px-2"}>
                                                    <div>
                                                        <img className={"img-fluid"}
                                                             src={"/images/landing/our-services-mobile-development-image.png"}
                                                             alt={"our services mobile development image"}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 py-3 px-2"}>
                                                    <label
                                                        className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>
                                                        Mobile App Development
                                                    </label>
                                                </div>
                                                <div className={"col-12 px-2"}>
                                                    <label
                                                        className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                        Reach your customers on the go with our mobile app development
                                                        services. Whether you need an iOS, Android, or cross-platform
                                                        solution, we'll build an app that engages users and drives
                                                        results.
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className={"row p-md-4 px-3 py-4 text-center text-md-start bg-white our-services-container-border mb-md-4 mb-3"}>
                                                <div className={"col-12 col-md-2 px-2"}>
                                                    <div>
                                                        <img className={"img-fluid"}
                                                             src={"/images/landing/our-services-cloud-solutions-image.png"}
                                                             alt={"our services cloud solutions image"}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 py-3 px-2"}>
                                                    <label
                                                        className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>
                                                        Cloud Solutions
                                                    </label>
                                                </div>
                                                <div className={"col-12 px-2"}>
                                                    <label
                                                        className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                        Embrace the flexibility and scalability of the cloud with our
                                                        cloud solutions. From cloud migration and infrastructure setup
                                                        to ongoing management and optimization, we'll help you leverage
                                                        the full potential of cloud technology.
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className={"row p-md-4 px-3 py-4 text-center text-md-start bg-white our-services-container-border mb-md-4 mb-3"}>
                                                <div className={"col-12 col-md-2 px-2"}>
                                                    <div>
                                                        <img className={"img-fluid"}
                                                             src={"/images/landing/our-services-It-consulting-image.png"}
                                                             alt={"our services It consulting image"}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 py-3 px-2"}>
                                                    <label
                                                        className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>
                                                        IT Consulting
                                                    </label>
                                                </div>
                                                <div className={"col-12 px-2"}>
                                                    <label
                                                        className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                        Navigate the complexities of IT with our expert consulting
                                                        services. Whether you need strategic guidance, technology
                                                        assessments, or assistance with implementation, our team of IT
                                                        consultants is here to help.
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className={"row p-md-4 px-3 py-4 text-center text-md-start bg-white our-services-container-border mb-md-4 mb-3"}>
                                                <div className={"col-12 col-md-2 px-2"}>
                                                    <div>
                                                        <img className={"img-fluid"}
                                                             src={"/images/landing/our-services-cyber-security-image.png"}
                                                             alt={"our services cyber security image"}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 py-3 px-2"}>
                                                    <label
                                                        className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>
                                                        Cyber Security
                                                    </label>
                                                </div>
                                                <div className={"col-12 px-2"}>
                                                    <label
                                                        className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                        Protect your business from cyber threats with our comprehensive
                                                        cybersecurity services. From risk assessments and threat
                                                        detection to incident response and compliance assistance, we'll
                                                        help you safeguard your data and assets.
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className={"row p-md-4 p-3 text-center text-md-start bg-white our-services-container-border"}>
                                                <div className={"col-12 col-md-2 px-2"}>
                                                    <div>
                                                        <img className={"img-fluid"}
                                                             src={"/images/landing/our-services-digital-marketing-image.png"}
                                                             alt={"our services digital marketing image"}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 py-3 px-2"}>
                                                    <label
                                                        className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>
                                                        Digital Marketing
                                                    </label>
                                                </div>
                                                <div className={"col-12 px-2"}>
                                                    <label
                                                        className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                        Expand your online presence and drive growth with our digital
                                                        marketing services. From search engine optimization (SEO) and
                                                        pay-per-click (PPC) advertising to social media management and
                                                        content marketing, we'll help you attract, engage, and convert
                                                        customers online.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* why choose us */}
                    <div className={"row justify-content-center py-md-2"}>
                        <div className={"col-12 col-md-10 py-4 my-md-4"}>
                            <div className={"w-100 d-flex justify-content-center text-center pb-md-4 mb-4"}>
                                <label className={"font-size-42 font-weight-700 font-color-neutral-grey-darkest"}>Why
                                    Choose Schematix</label>
                            </div>
                            <div className={"row mt-md-4 pt-md-2 justify-content-between px-2"}>
                                <div className={"col-12 col-md-6 h-100 d-flex flex-column align-items-center"}>
                                    <div
                                        className={"p-4 bg-shadow-why-choose-details-container bg-white text-center flex-grow-1"}>
                                        <div className={"mt-2 mb-3"}>
                                            <img className={"img-fluid"}
                                                 src={"/images/landing/why-choose-expertise-image.png"}
                                                 alt={"why choose expertise image"}/>
                                        </div>
                                        <div className={"px-2 mb-4"}>
                                            <label
                                                className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>Expertise</label>
                                        </div>
                                        <div className={"mb-2 px-2"}>
                                            <label
                                                className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                Our team consists of seasoned professionals with years of experience in
                                                their respective fields. We bring a wealth of knowledge and expertise to
                                                every project we undertake.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"col-12 col-md-6 h-100 d-flex flex-column align-items-center mt-3 mt-md-0"}>
                                    <div
                                        className={"p-4 bg-shadow-why-choose-details-container bg-white text-center flex-grow-1"}>
                                        <div className={"mt-2 mb-3"}>
                                            <img className={"img-fluid"}
                                                 src={"/images/landing/why-choose-custom-solutions-image.png"}
                                                 alt={"why choose custom solutions image"}/>
                                        </div>
                                        <div className={"px-2 mb-4"}>
                                            <label
                                                className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>Custom
                                                Solutions</label>
                                        </div>
                                        <div className={"mb-2 px-2"}>
                                            <label
                                                className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                We understand that every business is unique. That's why we take a
                                                personalized approach to each project, crafting custom solutions that
                                                align with your specific goals and objectives.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row mt-md-4 mt-3 pt-md-2 d-flex justify-content-between px-2"}>
                                <div className={"col-12 col-md-6 d-flex flex-column align-items-center"}>
                                    <div
                                        className={"p-4 bg-shadow-why-choose-details-container bg-white text-center flex-grow-1"}>
                                        <div className={"mt-2 mb-3"}>
                                            <img className={"img-fluid"}
                                                 src={"/images/landing/why-choose-quality-assurance-image.png"}
                                                 alt={"why choose quality assurance image"}/>
                                        </div>
                                        <div className={"px-2 mb-4"}>
                                            <label
                                                className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>Quality
                                                Assurance</label>
                                        </div>
                                        <div className={"mb-2 px-2"}>
                                            <label
                                                className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                We're committed to delivering high quality solutions that meet the
                                                highest standards of excellence. From rigorous testing to thorough
                                                documentation, we ensure that every project meets or exceeds your
                                                expectations.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12 col-md-6 d-flex flex-column align-items-center mt-3 mt-md-0"}>
                                    <div
                                        className={"p-4 bg-shadow-why-choose-details-container bg-white text-center flex-grow-1"}>
                                        <div className={"mt-2 mb-3"}>
                                            <img className={"img-fluid"}
                                                 src={"/images/landing/why-choose-customer-satisfaction-image.png"}
                                                 alt={"why choose customer satisfaction image"}/>
                                        </div>
                                        <div className={"px-2 mb-4"}>
                                            <label
                                                className={"font-size-24 font-weight-700 font-color-neutral-grey-darkest"}>Customer
                                                Satisfaction</label>
                                        </div>
                                        <div className={"mb-2 px-2"}>
                                            <label
                                                className={"font-size-14 font-weight-400 font-color-neutral-grey-dark"}>
                                                Your satisfaction is our top priority. We work closely with you
                                                throughout every step of the process to ensure that your needs are met
                                                and your expectations are exceeded.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* cta section */}
                    <div className={"row justify-content-center py-md-2"}>
                        <div className={"col-md-10 col-11 py-4 my-md-4"}>
                            <div className={"row bg-cta-container justify-content-center py-4 br-16"}>
                                <div className={"col-12 col-md-9 py-md-3 px-3  text-center"}>
                                    <div>
                                        <label className={"text-white font-size-42 font-weight-700"}>
                                            Get Started Today
                                        </label>
                                    </div>
                                    <div className={"mt-md-3 mt-2 mb-4"}>
                                        <label className={"text-white font-size-18 font-weight-400"}>
                                            Ready to take your business to the next level with cutting-edge technology
                                            solutions? Contact us today to schedule a consultation and learn more about
                                            how Schematix can help you achieve your goals. Let's innovate together!
                                        </label>
                                    </div>
                                    <div className={"pt-md-3 pt-2"}>
                                        <Link to={"/contactus"}>
                                            <button type={"button"}
                                                    className={"btn-contact-us border-0 text-white font-size-18 font-weight-500"}>
                                                Contact us
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default HomePage
