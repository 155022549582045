import FooterPage from "../FooterPage/footerPage";
import NavbarPage from "../NavBarPage/navbarPage";
import {useEffect} from "react";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"container-fluid p-0"}>
            <NavbarPage/>
            <div className={"row pt-5 bg-background-light-sky-color"}>
                <div className={"col-12"}>
                    <div className={"row justify-content-center py-4 my-md-5"}>
                        <div className={"col-10"}>
                            <div
                                className="w-100 d-flex flex-column pb-4 mb-md-3 align-items-center justify-content-center">
                                <label
                                    className={"font-color-primary-100 text-center font-family-play font-size-42 font-weight-700"}>
                                    Privacy Policy
                                </label>
                                <div className={"mt-md-4 mt-2"}>
                                    <label className={"font-size-18 font-weight-400 font-color-primary-100"}>Effective
                                        date: April 3, 2023</label>
                                </div>
                            </div>

                            <div>
                                <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                    Schematix is committed to the protection of your privacy. We understand that keeping
                                    your information secure and confidential is critical to earning and keeping your
                                    trust. This Privacy Policy describes the specific policies and procedures we use to
                                    collect, utilize, disclose, share and protect your Personal Information.
                                </label>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        This Privacy Policy governs your use of our services, along with our Terms of
                                        Service into which this Privacy Policy is incorporated by reference.
                                    </label>
                                </div>
                            </div>

                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>How
                                        to use this privacy policy</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        This Privacy Policy explains how Schematix gathers and uses your Personal
                                        Information in the context of each of Schematix service offerings. All of
                                        Schematix’s services are made available through Schematix’s website,
                                        www.Schematix.com (“Site”). In particular, we describe how your Personal
                                        Information is shared and/or disclosed, and how we collect and use other
                                        non-Personal Information. We also describe how we address other privacy and
                                        security matters – such as our overall security practices, deletion of your
                                        Personal Information upon request, and opting-out of marketing communications.
                                        Lastly, we describe methods for contacting Schematix if you have privacy or
                                        security questions, comments or feedback.
                                    </label>
                                </div>
                            </div>
                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Information
                                        collection</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        The types of information we collect depend on whether you are a current or
                                        former User, Visitor, Customer and/or Client. We collect Personal Information
                                        from you when you voluntarily provide it to us. Examples of instances when we
                                        collect Personal Information include: </label>
                                    <div className={"mt-3"}>
                                        <ul className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                            <li>when you answer questions on the Site to determine what kind of services
                                                we might recommend if you were to become a Customer and/or a Client;
                                            </li>
                                            <li>when you provide information about the company you have equity in;</li>
                                            <li>when you register to open an account;</li>
                                            <li>when you contact our client service organization with questions or;</li>
                                            <li>when you become a Customer or Client;</li>
                                            <li>Wherever Schematix collects Personal Information, we endeavor to provide
                                                a link to this Privacy Policy.
                                            </li>
                                        </ul>
                                        {/*<label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>*/}
                                        {/*    Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.*/}
                                        {/*</label>*/}
                                    </div>
                                </div>
                            </div>
                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Information
                                        we collect</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        We collect Information through our Website to provide better services and
                                        results to our Users. Information is collected by the details provided by User
                                        and the data collected by the usage of the services. Details provided by User
                                        shall include Personal Information like name, address (residential or email),
                                        contact number, and other such data which is required for the efficient use of
                                        the services provided by the Company. In general, the user can browse the
                                        Website without revealing any Personal Information. Although once Personal
                                        Information is provided, the identity of the User is not anonymous anymore.
                                        Where possible, we indicate which fields are required and which fields are
                                        optional. Our User always has the option of not providing Information by
                                        choosing not to use a particular service or feature. Other Information collected
                                        includes device information i.e. from which device the services being used. This
                                        shall include the hardware model, operating system, unique identification number
                                        associated with the device etc.
                                    </label>
                                </div>
                            </div>
                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Other
                                        ways we collect information</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        Other means by which we collect Personal Information include the following:
                                    </label>
                                    <ul className={"font-color-neutral-grey-light mt-md-3 mt-2 font-size-18 font-weight-400"}>
                                        <li>Anonymized or aggregated Information: Schematix’s Site automatically records
                                            certain anonymized or aggregated information about your use or evaluation of
                                            the Services. Anonymized or aggregated information is used for a variety of
                                            functions, including the measurement of Users’ interest in and use of
                                            various portions or features of the Site. Anonymized or aggregated
                                            information is not Personal Information, and we may use and distribute such
                                            information in a number of ways, including internal analysis, analytics and
                                            research. We may share this information with third parties for our purposes
                                            in an anonymized or aggregated form that is designed to prevent anyone from
                                            identifying you.
                                        </li>
                                        <li>Cookies and Pixels: Similar to other technology platforms and services,
                                            Schematix uses cookies, a small piece of computer code that enables our Web
                                            servers to “identify” Users, each time an individual initiates a session on
                                            our websites. A cookie is set in order to identify you and tailor the Site
                                            to you. Cookies do not store any of the Personal Information that you
                                            provided to us; they are simply identifiers. You may delete cookie files
                                            from your own hard drive at any time. However, cookies may be necessary to
                                            provide access to much of the content and many of the features of the Site.
                                        </li>
                                        <li>Pixel Tags: Along with cookies, we may use “pixel tags,” also known as “web
                                            beacons,” which are small graphic files that allow us to monitor the use of
                                            our Site. A pixel tag can collect information such as the Internet Protocol
                                            (“IP”) address of the computer that downloaded the page on which the tag
                                            appears; the URL of the page on which the pixel tag appears; the time the
                                            page containing the pixel tag was viewed; the type of browser that fetched
                                            the pixel tag; and the identification number of any cookie on the computer
                                            previously placed by that server.
                                        </li>
                                        <li>Site Activity: Schematix may also use third-party tracking technology, such
                                            as Google Analytics, to record similar information regarding you and your
                                            activity on our Site. For more information on these third parties, including
                                            how to opt out from certain data collection, please visit the site below.
                                            Please be advised that if you opt out of any service, you may not be able to
                                            use the full functionality of the Site or our Services.
                                        </li>
                                        <li>“Do Not Track” Technology: We do not collect personal information about your
                                            online activities over time and across different web sites or online
                                            services. Therefore, our Site does not respond to Do Not Track (“DNT”)
                                            signals. We do not knowingly authorize third parties to collect Personal
                                            Information about your online activities over time and across different web
                                            sites or online services.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Usage
                                        of the collected Information</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        The Information collected is constantly used to provide personally relevant
                                        features and improve the services for our Users. With such a pool of
                                        information, it is easier for us to understand the current trends in the market,
                                        according to which we cater our services. Such information helps us to come with
                                        content that provides a better User experience, thereby creating better
                                        satisfaction and increasing the user base of our Website. User discretion is
                                        asked before using such information for any other purposes than those set out in
                                        this Policy. Although the information which is shared by the User themselves
                                        through us shall not be considered as making private information public.
                                    </label>
                                </div>
                            </div>
                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Sharing
                                        of Personal Information</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        Disclosure may be necessary to provide Users access to our Services, to comply
                                        with our legal obligations, to enforce our User Agreement, to prevent, detect,
                                        mitigate, and investigate fraudulent or illegal activities related to our
                                        Services. Such Personal Information, when shared with third parties, shall be
                                        subject to strict confidentiality agreements. We may disclose personal
                                        information if required to do so by law or in the good faith belief that such
                                        disclosure is reasonably necessary to respond to subpoenas, court orders, or
                                        other legal process. We may disclose Personal Information to law enforcement
                                        offices, third party rights owners, or others in the good faith belief that such
                                        disclosure is reasonably necessary to enforce our Terms or Privacy Policy. We
                                        and our affiliates will share / sell some or all of your personal information
                                        with another business entity should we (or our assets) plan to merge with, or be
                                        acquired by that business entity, or reorganisation, amalgamation, restructuring
                                        of business. Should such a transaction occur that another business entity (or
                                        the new combined entity) will be required to follow this privacy policy with
                                        respect to your Personal Information
                                    </label>
                                </div>
                            </div>
                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Securing
                                        Information</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        In order to secure the information provided to us by our users, we review the
                                        processing and storage practices along with the information collected to guard
                                        against unauthorized access into the data collected in our systems. Furthermore,
                                        user information is protected by subjecting our Employees and Agents who process
                                        such information to strict confidentiality agreements, where if they fail to
                                        comply with it, such non compliance shall lead to heavy penalties or legal
                                        proceedings, depending upon the gravity of the situation.
                                    </label>
                                </div>
                            </div>
                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Choice/Opt-Out</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        We provide all our users with the opportunity to opt-out of receiving
                                        promotional, marketing-related communications from us in general, after setting
                                        up an account. Users may remove their personal information by unsubscribing from
                                        our mailing list.
                                    </label>
                                </div>
                            </div>
                            <div className={"mt-md-5 mt-3"}>
                                <div>
                                    <label
                                        className={"font-color-primary-100 font-family-play font-size-24 font-weight-700"}>Consent</label>
                                </div>
                                <div className={"mt-md-3 mt-2"}>
                                    <label className={"font-color-neutral-grey-light font-size-18 font-weight-400"}>
                                        By using the Website and by providing personal information, the user consents to
                                        the collection and use of the information disclosed by them in accordance with
                                        this Privacy Policy, including but not limited to their consent for sharing
                                        their information as per this privacy policy. Our Website offers publicly
                                        accessible blogs or community forums. You should be aware that any information
                                        you provide in these areas may be read, collected, and used by others who access
                                        them. This document is an electronic record in terms of existing and applicable
                                        Information Technology laws and the amended provisions thereto pertaining to
                                        electronic records in various allied statutes as amended pursuant to the
                                        Information Technology laws. This electronic record has been generated by a
                                        computer system and does not require any authentication. From time to time, the
                                        Company may change this internet privacy policy. The effective date of this
                                        policy, as stated below, indicates the last time this policy was revised or
                                        materially changed. Checking the effective date below allows you to determine
                                        whether there have been changes since the last time you reviewed the policy.
                                        Effective date of this policy: This policy was last updated on.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPage/>
        </div>
    )
}

export default PrivacyPolicy
