import logo from './logo.svg';
import './App.css';
import PrivacyPolicy from "./components/PrivacyPolicyPage/privacyPolicy";
import HomePage from "./components/LandingPage/homePage";
import './components/styles/scss/main.scss'
import 'bootstrap/dist/css/bootstrap.css'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import TermsAndConditions from "./components/TermsAndConditionsPage/termsAndConditions";
import ContactUs from "./components/ContactUs/contactUs";

function App() {
  return (
    <div className="">
      <BrowserRouter>
          <Routes>
              <Route path={"/"} element={<HomePage/>} />
              <Route path={"/contactus"} element={<ContactUs/>} />
              <Route path={"/privacy-policy"} element={<PrivacyPolicy/>} />
              <Route path={"/terms-conditions"} element={<TermsAndConditions/>} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
