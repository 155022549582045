import NavbarPage from "../NavBarPage/navbarPage";
import {useEffect, useState} from "react";
import {TailSpin} from "react-loader-spinner";
import FooterPage from "../FooterPage/footerPage";

const ContactUs = () => {

    const [loadingButton, setLoadingButton] = useState(false);
    const [showMessage, setShowMessage] = useState('');


    const handleOnFormSubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('enquiry-form');
        const data = new FormData(form);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,
        })
            .then(() => {
                setLoadingButton(false);
                setShowMessage('success');
                form.reset();
            }).catch((error) => {
            setLoadingButton(false);
            setShowMessage('error');
            form.reset();
            console.log(error)
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-body-background-color min-vh-100"}>
                <div className={"col-12"}>
                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-4"}></div>
                    </div>

                    <div className={"row justify-content-center py-md-5 my-2 py-4"}>
                        <div className={"col-12 pb-4 mb-md-3 d-flex justify-content-center"}>
                            <div className={""}>
                                <label
                                    className={"font-size-42 font-weight-700 font-family-play font-color-primary-100"}>Contact
                                    Us</label>
                            </div>
                        </div>
                        <div className={"col-10 bg-primary-default justify-content-center rounded-5"}>
                            <div className={"row py-md-5 py-4 mt-md-2 mt-md-0 d-flex justify-content-around"}>
                                <div className={"col-md-4 col-11 order-md-0 order-1 mt-4 mt-md-0"}>
                                    <div className={"row d-flex flex-column align-items-md-center"}>
                                        <div className={"col-12 pt-md-3"}></div>
                                        <div className={"col-md-10 col-11"}>
                                            <label className={"font-size-32 font-weight-700 text-white"}>Visiting
                                                hours</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-primary-30"}>Monday
                                                    - Friday</label>
                                                <div>
                                                    <label
                                                        className={"font-size-18 font-weight-400p font-color-primary-30"}>10:00
                                                        am - 11:00 pm</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 my-3 py-md-4"}></div>
                                        <div className={"col-md-10 col-11"}>
                                            <label className={"font-size-32 font-weight-700 text-white"}>Our
                                                Addresss</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-primary-30"}> Los
                                                    Angeles, California</label>
                                            </div>
                                        </div>
                                        <div className={"col-12 my-3 py-md-4"}></div>
                                        <div className={"col-md-10 col-11"}>
                                            <label className={"font-size-32 font-weight-700 text-white"}>Get
                                                in touchs</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-primary-30"}>+1
                                                    (310) 650-1338</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-6 col-11 mt-md-4 mt-md-0 rounded-4 bg-white p-4"}>
                                    <div>
                                        <label className={"font-size-32 font-weight-700 font-color-primary-100"}>Send us
                                            a message</label>
                                        <div className={"mt-3"}>
                                            <label
                                                className={"font-size-18 font-weight-400 font-color-neutral-grey-darker"}>We
                                                will get in touch with you shortly</label>
                                        </div>
                                    </div>
                                    <form className={"d-flex flex-column mt-4 pt-3"} method={"POST"}
                                          id={'enquiry-form'}
                                          onSubmit={handleOnFormSubmit}
                                          action={"https://script.google.com/macros/s/AKfycbwaI60glUeyp_6iv6c4fWnvISIk9iqBu6rchLk2pLT-40j-htzDgWbCZy32A6AZ9Bco/exec"}
                                    >
                                        <div className={"w-100"}>
                                            <label
                                                className={"font-size-18 font-weight-500 font-color-neutral-grey-darkest"}
                                                htmlFor={'fullName'}>Your
                                                Name</label>
                                            <input name={'FullName'} type={"text"} id={'fullName'}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'phoneNumber'}
                                                   className={"font-size-18 font-weight-500 font-color-neutral-grey-darkest"}>Your
                                                mobile number</label>
                                            <input name={"PhoneNumber"} id={'phoneNumber'} type={"number"}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'email'}
                                                   className={"font-size-18 font-weight-500 font-color-neutral-grey-darkest"}>Your
                                                e-mail id(optional)</label>
                                            <input name={'Email'} id={'email'} type={"email"}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'message'}
                                                   className={"font-size-18 font-weight-500 font-color-neutral-grey-darkest"}>Write
                                                your message</label>
                                            <textarea name={'Message'} id={'message'} rows={5}
                                                      className={"form-control"}></textarea>
                                        </div>
                                        <div className={"mt-4 pt-3"}>
                                            <button type="submit" disabled={loadingButton}
                                                    className={"btn-primary-color font-color-neutral-grey-darkest font-size-18 font-weight-500 text-white border-0 bg-btn-shadow"}>
                                                {loadingButton ? <TailSpin width={20} height={20}
                                                                           color={'#FFFFFF'}/> : 'Send Message'}
                                            </button>
                                        </div>
                                        {showMessage === 'success' && (
                                            <div className={"mt-4 bg-light-green p-md-4 p-3 br-8px"}>
                                                <label
                                                    className={"font-color-dark-green font-size-18 font-weight-500p"}>Thank
                                                    you for contacting us! Your message has been successfully
                                                    received. We will get back to you shortly.</label>
                                            </div>)}
                                        {showMessage === 'error' && (
                                            <div className={"mt-4 bg-light-red p-md-4 p-3 br-8px"}>
                                                <label
                                                    className={"font-color-neutral-grey-darkest font-size-18 font-weight-500p"}>Oops!
                                                    Something went wrong while submitting your message. Please try
                                                    again later. Thank you for your understanding.</label>
                                            </div>)}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
