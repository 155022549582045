import "bootstrap/dist/css/bootstrap.css"
import {Link} from "react-router-dom";

const FooterPage = () => (
    <div className={"row bg-footer-color py-2"}>
        <div className={"col-12 py-4 my-4"}>
            <div className={"row justify-content-center"}>
                <div className={"col-10 justify-content-center"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-3 d-flex justify-content-center"}>
                            <Link to={"/"}>
                                <label className={"font-size-28 font-family-play font-weight-700 text-white"}>Schematix</label>
                            </Link>
                        </div>
                    </div>
                    <div className={"row justify-content-center"}>
                        <div className={"col-md-6 col-12 d-flex justify-content-around flex-wrap gap-2 mt-5"}>
                            <div className={""}>
                                <Link to={"/terms-conditions"}>
                                    <button type={"button"} className={"text-white p-0 border-0 bg-transparent font-size-16 font-weight-500"}>
                                        Terms and conditions
                                    </button>
                                </Link>
                            </div>
                            <div className={""}>
                                <Link to={"/contactus"}>
                                    <button type={"button"} className={"text-white p-0 border-0 bg-transparent font-size-16 font-weight-500"}>
                                        Contact US
                                    </button>
                                </Link>
                            </div>
                            <div className={""}>
                                <Link to={"/privacy-policy"}>
                                    <button type={"button"} className={"text-white p-0 border-0 bg-transparent font-size-16 font-weight-500"}>
                                        Privacy Policy
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={"row justify-content-center pt-md-4"}>
                <div className={"col-11 mt-3 "}>
                    <hr className={"border-color-grey-1 "} />

                    <div className={"row mt-5"}>
                        <div className={"col-12"}>
                            <div className={"row"}>
                                <div className={"col-md-6 col-12"}>
                                    <label className={"text-white font-size-14 font-weight-400"}>
                                        © 2024 Schematix. All rights reserved.
                                    </label>
                                </div>
                                <div className={"d-flex justify-content-md-end justify-content-between mt-3 mt-md-0 col-md-6 col-12 gap-md-5 gap-2"}>
                                    <div>
                                        <Link to={"/privacy-policy"}>
                                            <button type={"button"} className={"text-white bg-transparent border-0 p-0 font-size-14 font-weight-400"}>
                                                Privacy Policy
                                            </button>
                                        </Link>
                                    </div>

                                    <div>
                                        <Link to={"/terms-conditions"}>
                                            <button type={"button"} className={"text-white bg-transparent border-0 p-0 font-size-14 font-weight-400"}>
                                                Terms and conditions
                                            </button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default FooterPage
